import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  constructor(private http: HttpClient) { }

  getSetup(eventId): any{
    // return this.http.get(`https://platform3.staging.eventup.eu/api/business/v1/${eventId}/website/setup`);
    return this.http.get(`https://platform3.eventup.eu/api/business/v1/${eventId}/website/setup`);
    // return this.http.get(`http://192.168.1.134:31337/api/business/v1/${eventId}/website/setup`);
  }
}
