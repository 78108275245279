import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClassificationService {

  constructor(private http: HttpClient) { }

  getClassificaiton(eventId, classificationId, userId): any{
    // return this.http.get(`https://platform3.staging.eventup.eu/api/business/v1/${eventId}/ranking_groups/${classificationId}` , {headers: {USER_ID: userId} });
    return this.http.get(`https://platform3.eventup.eu/api/business/v1/${eventId}/ranking_groups/${classificationId}` , {headers: {USER_ID: userId} });
      //  return this.http.get(`http://192.168.1.134:31337/api/business/v1/${eventId}/ranking_groups/${classificationId}` , {headers: {USER_ID: userId} });
  }
}
